import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SectionHeader from "./SectionHeader";
import IndividualFeaturedProject from "./IndividualFeaturedProject";

const Styles = styled.div`
  .featured-projects-wrapper {
    padding: 0 64px;
  }
  .contact-us-container p {
    padding: 0 17px;
    text-align: center;
    color: ${(props) => props.theme.text.paragraph};
  }
  .contact-us-button {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    background-color: transparent;
    color: ${(props) => props.theme.button};
    padding: 21px 34px;
    text-align: center;
    width: 100px;
    margin: auto;
    transition: background 0.1s ease-in, color 0.1s ease-in;
  }
  .contact-us-button:hover {
    color: ${(props) => props.theme.buttonInverse};
    background: ${(props) => props.theme.button};
  }
  .contact-us-text {
    text-decoration: none;
  }
`;

const projects = [
  {
    projectTitle: "TSheets Time Tracking Chrome App",
    projectDescription:
      "To enable TSheets to enter the desktop app space, we created a Chrome Packaged App using AngularJS. It is a fully offline-capable application with over 10,000 users.",
    buttonText: "READ MORE ABOUT THE TSHEETS PROJECT",
    imageName: "tsheets-time-card",
    imageLink: undefined,
    writeupPrompt: "See our full project writeup.",
    writeupLink: "/tsheets-chrome-app",
  },
  {
    projectTitle: "Vacasa Executive Dashboard",
    projectDescription:
      "We created an Executive Dashboard for Vacasa to visualize their critical Key Performance Indicators (KPI) across the entire company.  We used React along with D3.js to create dynamic data visualizations using charts and maps.",
    buttonText: "READ MORE ABOUT THE VACASA PROJECT",
    imageName: "vacasa-map",
    imageLink: "/vacasa-executive-dashboard",
    writeupPrompt: "See our full Vacasa Executive Dashboard writeup.",
    writeupLink: "/vacasa-executive-dashboard",
  },
  {
    projectTitle: "The Krazy Coupon Lady",
    projectDescription:
      "We helped The Krazy Coupon Lady with tech advising and strategy along with development on their mainly Javascript-based tech stack.",
    buttonText: "READ MORE ABOUT OUR THE KRAZY COUPON LADY WORK",
    imageName: "kcl-project",
    imageLink: "/the-krazy-coupon-lady",
    writeupPrompt: "See our full The Krazy Coupon Lady writeup.",
    writeupLink: "/the-krazy-coupon-lady",
  },
  {
    projectTitle: "Boise State University",
    projectDescription:
      "At Boise State University, the First Year Writing Program team had developed an algorithm to guide incoming students into the proper first-year writing class.  We took this system, The Write Class™ (TWC), and developed it into a web application.  TWC is a unique system that uses self-directed placement to assess the student and find the perfect class fit.",
    buttonText: "READ MORE ABOUT THE BOISE STATE UNIVERSITY PROJECT",
    imageName: "bsu-student-flow",
    imageLink: "/boise-state-university-the-write-class",
    writeupPrompt: "See our full Boise State University project writeup.",
    writeupLink: "/boise-state-university-the-write-class",
  },
  {
    projectTitle: "Suds Creative",
    projectDescription:
      "We helped Suds Creative by filling the role of tech strategy and engineering team leadership, along with architecture and development on all aspects of their web tech stack.",
    buttonText: "READ MORE ABOUT OUR SUDS CREATIVE WORK",
    imageName: "suds-logo",
    imageLink: "/suds-creative",
    writeupPrompt: "See our full Suds Creative writeup.",
    writeupLink: "/suds-creative",
  },
  // {
  //   projectTitle: "Balihoo Help Center",
  //   projectDescription:
  //     "To extend the online available support for their suite of products, Balihoo was looking for a support site that was driven by search but also by client profile.  What resulted was a collaboration between the Balihoo internal graphic design team and our technical capabilities to build out a Drupal support site.  We use a vertical content structure as more of a guided process, but then a horizontal content discovery by using related terms.  The goal was to provide a useful support site even if search wasn't used.",
  //   buttonText: undefined,
  //   imageName: "balihoo-help-center",
  //   imageLink: undefined,
  //   writeupPrompt: undefined,
  //   writeupLink: undefined,
  // },
];

export default function FeaturedProjects() {
  return (
    <Styles>
      <SectionHeader>Featured Projects</SectionHeader>
      <div className="featured-projects-wrapper">
        {projects.map((project) => (
          <IndividualFeaturedProject
            key={project.projectTitle}
            projectTitle={project.projectTitle}
            projectDescription={project.projectDescription}
            writeupPrompt={project.writeupPrompt}
            buttonText={project.buttonText}
            imageName={project.imageName}
            imageLink={project.imageLink}
            writeupLink={project.writeupLink}
          />
        ))}
      </div>
      <div className="contact-us-container">
        <p>
          Busy building software... many more projects but not all listed.
          Contact Us
        </p>
        <Link to="/contact" className="contact-us-text">
          <div className="contact-us-button">CONTACT US</div>
        </Link>
      </div>
    </Styles>
  );
}
