import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import SectionHeader from "./SectionHeader";

const Styles = styled.div`
  padding: 0 36px;

  .services-wrapper {
    display: flex;
  }
  .service-title {
    margin-top: 0;
  }
  .service-description {
    color: ${(props) => props.theme.text.paragraph};
  }
  .service-text-column {
    padding: 17px;
  }
  .service-column {
    width: 33.33%;
  }

  .service-img {
    width: 89%;
    height: 144px;
    padding: 17px;
    margin: auto;
    display: flex;
  }

  @media screen and (max-width: 640px) {
    .service-column {
      width: auto;
      padding: 17px;
    }
    .services-wrapper {
      display: block;
    }
    img {
      width: 96%;
      height: auto;
      padding: 0;
      padding-bottom: 17px;
    }
  }
`;

export default function Services() {
  return (
    <Styles>
      <SectionHeader>Services</SectionHeader>
      <div className="services-wrapper">
        <div className="service-column">
          <div className="service-img">
            <StaticImage
              src="../images/home/services/services-1.jpeg"
              alt="Project Management"
              placeholder="blurred"
            />
          </div>
          <div className="service-text-column">
            <h3 className="service-title">Project Management</h3>
            <p className="service-description">
              We help enable your team to focus on their work while we take care
              of project management. Clear communication and attention to detail
              is what sets us apart.
            </p>
          </div>
        </div>
        <div className="service-column">
          <div className="service-img">
            <StaticImage
              src="../images/home/services/services-2.jpeg"
              alt="Coding"
              placeholder="blurred"
            />
          </div>
          <div className="service-text-column">
            <h3 className="service-title">Coding</h3>
            <p className="service-description">
              We use frameworks that are mature and proven so your product will
              be built upon a solid foundation. We know client and server side,
              so we know everything it takes to make an app succeed.
            </p>
          </div>
        </div>
        <div className="service-column">
          <div className="service-img">
            <StaticImage
              src="../images/home/services/services-3.jpeg"
              alt="Tech Strategy"
              placeholder="blurred"
            />
          </div>
          <div className="service-text-column">
            <h3 className="service-title">Tech Strategy</h3>
            <p className="service-description">
              Have an idea but don&apos;t know how to get there? We do. Figure
              out how to turn your idea into reality in an iterative approach to
              minimize expense and maximize feedback.
            </p>
          </div>
        </div>
      </div>
    </Styles>
  );
}
