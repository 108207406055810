import React from "react";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";

const Styles = styled.div`
  .our-approach-wrapper {
    padding: 17px;
    margin: auto;
  }
  .approach-container p {
    line-height: 2em;
    color: ${(props) => props.theme.text.paragraph};
  }

  @media screen and (min-width: 641px) {
    .our-approach-wrapper {
      width: 60%;
    }
  }
`;

export default function OurApproach() {
  return (
    <Styles>
      <SectionHeader>Our Approach</SectionHeader>
      <div className="our-approach-wrapper">
        <div className="approach-container">
          <h3>Technical Project Management</h3>
          <p>
            No barriers to contacting the developers doing the work on the
            project. The key to a successful software project is having coders
            and the client and users in direct contact. Decisions can be made
            more efficiently and questions answered promptly.
          </p>
        </div>
        <div className="approach-container">
          <h3>Transparent Development Process</h3>
          <p>
            Working with contractors can be a big adjustment. You typically
            don&apos;t have the visibility to day to day progress like you would
            have with your own team. It doesn&apos;t have to be that way. We
            maintain constant contact by giving clients direct access to our
            agile backlog and dedicated resources in any given week. This allows
            us to get your feedback and change direction quickly, like your own
            team could.
          </p>
        </div>
        <div className="approach-container">
          <h3>Small on Purpose</h3>
          <p>
            We stay small so we can do our highest quality work on a selective
            group of projects. We only take projects that are the best fit for
            our skills and interests. This allows us to focus on your project
            instead of us being stretched too thin. We limit our client work to
            4 days a week so we have the time to invest in training and
            exploring new technologies on our team to continue developing great
            software.
          </p>
        </div>
        <div className="approach-container">
          <h3>100% US Based</h3>
          <p>
            We never sub-contract out client work. This allows us to ensure your
            privacy and legal concerns about non-US countries are not a problem.
            We also don&apos;t lose the valuable experience of building your
            project to an outsourced team that goes away after launch. We retain
            it along with your internal team and stay with you after launch.
          </p>
        </div>
      </div>
    </Styles>
  );
}
