import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RichText from "./RichText";

const ArticleStyles = styled.div`
  margin: 60px 0;
  h1 {
    text-align: center;
    font-size: 25px;
    padding: 0 40px;
  }
  h2 {
    color: ${(props) => props.theme.text.darkest};
    line-height: 2em;
    font-weight: 300;
    font-size: 20px;
  }

  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  .article-content-container {
    width: 66.667%;
    margin: auto;
  }
  .post-date {
    text-align: center;
    font-size: 11px;
    margin: 40px auto;
  }

  @media screen and (max-width: 640px) {
    .article-content-container {
      width: 100%;
    }
    hr {
      width: 100%;
    }
  }
`;

export default function Article({ title, date, body }) {
  return (
    <ArticleStyles>
      <article>
        <h1>{title}</h1>
        <p className="post-date">{date}</p>
        <div className="article-content-container">
          <RichText text={body} />
        </div>
      </article>
    </ArticleStyles>
  );
}

Article.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  body: PropTypes.shape({}).isRequired,
};
