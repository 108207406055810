import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import GridItems from "./GridItems";

const TechnologiesUsedStyles = styled.div`
  .technologies-used-container {
    display: flex;
  }
  .technologies-used-grid {
    flex: 1;
    padding: 0 40px;
  }
  .technologies-used-description {
    flex: 1;
    padding: 0 17px;
  }
  .disc {
    list-style-type: disc;
  }

  h2 {
    font-weight: 300;
    font-size: 20px;
  }
  li {
    font-weight: 300;
    font-size: 16px;
    color: ${(props) => props.theme.text.paragraph};
    line-height: 2em;
  }

  @media screen and (max-width: 640px) {
    .technologies-used-container {
      display: block;
    }
  }
`;

export default function TechnologiesUsed({
  gridItems,
  bulletItems,
  columnCount,
}) {
  return (
    <TechnologiesUsedStyles>
      <div className="technologies-used-container">
        <div className="technologies-used-grid">
          <GridItems
            gridItems={gridItems}
            columnCount={columnCount}
            responsiveCount={2}
          />
        </div>
        <div className="technologies-used-description">
          <h2>Technologies Used</h2>
          <ul className="disc">
            {bulletItems.map((bulletItem) => (
              <li key={bulletItem.bullet}>
                {bulletItem.bullet}
                {bulletItem.subBullets.length > 0 && (
                  <ul>
                    {bulletItem.subBullets.map((subBulletItem) => (
                      <li key={subBulletItem.bullet}>{subBulletItem.bullet}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </TechnologiesUsedStyles>
  );
}

TechnologiesUsed.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      imageName: PropTypes.string.isRequired,
    })
  ).isRequired,
  bulletItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columnCount: PropTypes.number.isRequired,
};
