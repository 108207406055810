import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faReddit,
  faTumblr,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";

const SocialMediaHeader = styled.header`
  .social-media-icon {
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    color: ${(props) => props.theme.text.paragraph};
    padding: 0 10px;
    cursor: pointer;
  }

  .fa:hover {
    opacity: 0.7;
  }
  .social-media-container {
    text-align: center;
  }
`;
const facebookShareLink =
  "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.zerrtech.com%2F";
const twitterShareLink =
  "https://www.twitter.com/intent/tweet?url=https%3A%2F%2Fwww.zerrtech.com%2Fblog%2F";
const linkedInShareLink =
  "https://www.linkedin.com/shareArticle?mini=true&source=Zerrtech&summary=&url=https%3A%2F%2Fwww.zerrtech.com%2Fblog%2F";
const redditShareLink =
  "https://www.reddit.com/submit?url=https%3A%2F%2Fwww.zerrtech.com%2Fblog%2F";
const tumblrShareLink =
  "https://www.tumblr.com/share/link?url=https%3A%2F%2Fwww.zerrtech.com%2Fblog%2F";
const pinterestShareLink =
  "https://www.pinterest.com/pin/create/link/?description=&media=https://images.squarespace-cdn.com/content/v1/5726c0f6e321405ebae84138/1528922042824-MT0J3IJ01UL0J1SKO0FD/react-logo-1000-transparent.png&url=https%3A%2F%2Fwww.zerrtech.com%2Fblog%2F";

export default function SocialMediaBar({ slug }) {
  return (
    <SocialMediaHeader>
      <div className="social-media-container">
        <a href={facebookShareLink + slug} className="social-media-icon">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href={twitterShareLink + slug} className="social-media-icon">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href={linkedInShareLink + slug} className="social-media-icon">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href={redditShareLink + slug} className="social-media-icon">
          <FontAwesomeIcon icon={faReddit} />
        </a>
        <a href={tumblrShareLink + slug} className="social-media-icon">
          <FontAwesomeIcon icon={faTumblr} />
        </a>
        <a href={pinterestShareLink + slug} className="social-media-icon">
          <FontAwesomeIcon icon={faPinterest} />
        </a>
      </div>
    </SocialMediaHeader>
  );
}

SocialMediaBar.propTypes = {
  slug: PropTypes.string.isRequired,
};
