import * as React from "react";
import PropTypes from "prop-types";

import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const getImagesQuery = graphql`
  query {
    allFile {
      edges {
        node {
          id
          name
          relativePath
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default function Image({ imageName, style, alt }) {
  return (
    <StaticQuery
      query={getImagesQuery}
      render={(data) => {
        const { node } = data.allFile.edges.find(
          (edge) => edge.node.name === imageName
        );

        const img = getImage(node);

        return <GatsbyImage style={style} image={img} alt={alt} />;
      }}
    />
  );
}

Image.defaultProps = {
  style: {},
};

Image.propTypes = {
  imageName: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  alt: PropTypes.string.isRequired,
};
