import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";
import RichText from "./RichText";

const ArticleStyles = styled.div`
  margin: 60px 0;
  h2 {
    text-align: left;
    font-size: 16px;
  }

  hr {
    width: 50%;
    margin-left: 0px;
    color: #ccc;
    height: 1px;
    border: none;
    background-color: #ccc;
  }
  .article-content-container {
    width: 66.667%;
    margin: auto;
  }
  @media screen and (max-width: 640px) {
    .article-content-container {
      width: 100%;
    }
    hr {
      width: 100%;
    }
  }
`;

export default function ArticleSummary({ title, slug, date, body }) {
  return (
    <ArticleStyles>
      <article>
        <Link to={`/blog/${slug}`}>
          <h2>{title}</h2>
        </Link>
        <h6>{date}</h6>
        <div className="article-content-container">
          <RichText text={body} />
        </div>
        <hr />
      </article>
    </ArticleStyles>
  );
}

ArticleSummary.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  body: PropTypes.shape({}).isRequired,
};
