import * as React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

const BlogLinkStyles = styled.div`
  .different-articles-link-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
  }
  .different-articles-link-container a {
    width: 50%;
  }
  .article-link-wrapper {
    text-decoration: none;
  }
  .article-link-wrapper h5 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0;
    color: ${(props) => props.theme.text.blogLinkHeader};
  }
  .article-link-wrapper h6 {
    font-size: 16px;
    margin-top: 0;
    color: ${(props) => props.theme.text.blogLinkTitle};
  }

  .article-link-wrapper.right {
    text-align: right;
    margin-left: auto;
  }
  .article-link-wrapper.left {
    text-align: left;
  }
`;

export default function BlogLinks({ prev, next }) {
  return (
    <BlogLinkStyles prev={prev} next={next}>
      <div className="different-articles-link-container">
        {prev && (
          <Link to={`/blog/${prev.slug}`} className="article-link-wrapper left">
            <div>
              <h5>Previous</h5>
              <h6>{prev.title}</h6>
            </div>
          </Link>
        )}
        {next && (
          <Link
            to={`/blog/${next.slug}`}
            className="article-link-wrapper right"
          >
            <div>
              <h5>Next</h5>
              <h6>{next.title}</h6>
            </div>
          </Link>
        )}
      </div>
    </BlogLinkStyles>
  );
}

BlogLinks.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  prev: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  next: PropTypes.object,
};

BlogLinks.defaultProps = {
  prev: null,
  next: null,
};
