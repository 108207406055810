import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  .footer-wrapper {
    padding: 0 64px;
    height: 254px;
    display: table-cell;
    vertical-align: middle;
}
  }
  a {
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    padding-bottom: 6px;
    text-decoration: none;
  }

  p{
    padding-bottom: 6px;
  }
`;

export default function Footer() {
  return (
    <Styles>
      <div className="footer-wrapper">
        <a href="https://www.linkedin.com/in/jrzerr/">
          <p>linkedin</p>
        </a>
        <a href="https://github.com/jrzerr">
          <p>github</p>
        </a>
      </div>
    </Styles>
  );
}
