import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const Styles = styled.div`
  display: grid;
  align-items: center;

  h1 {
    color: ${(props) => props.theme.text.lightest};
    text-align: center;
    line-height: 1em;
  }

  @media screen and (min-width: 960px) {
    h1 {
      font-size: 56px;
    }
  }

  .text {
    padding: 60px 20px;
  }
`;

export default function Hero() {
  return (
    <Styles>
      <StaticImage
        style={{
          gridArea: "1/1",
          height: "100%",
        }}
        layout="constrained"
        alt="Hero"
        src="../images/home/hero.jpeg"
        placeholder="blurred"
      />

      <div
        className="text"
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
        }}
      >
        <h1>Custom Apps</h1>
        <h1>Web and Mobile</h1>
        <h1>Crafted in Boise</h1>
      </div>
    </Styles>
  );
}
