import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SectionHeader from "./SectionHeader";
import GridItems from "./GridItems";

const Styles = styled.div`
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-content: center;
    grid-gap: 2rem;
    padding: 1rem;
  }

  img {
    width: 100%;
    object-fit: scale-down;
  }

  @media screen and (max-width: 640px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }

  p {
    width: 66.6%;
    margin: auto;
    font-weight: 300;
    color: ${(props) => props.theme.text.paragraph};
  }
`;

const gridItems = [
  {
    imageName: "tsheets",
    link: "https://www.tsheets.com/",
    text: "tsheets",
  },
  {
    imageName: "vacasa",
    link: "",
    text: "vacasa",
  },
  {
    imageName: "boise-state-university",
    link: "",
    text: "Boise State",
  },
  {
    imageName: "micron",
    link: "http://www.micron.com/",
    text: "Micron",
  },
  {
    imageName: "st-lukes",
    link: "https://www.stlukesonline.org/",
    text: "St. Lukes",
  },
  {
    imageName: "kcl",
    link: "https://thekrazycouponlady.com",
    text: "The Krazy Coupon Lady",
  },
  {
    imageName: "suds-logo",
    link: "https://sudscreative.com",
    text: "Suds Creative",
  },
  {
    imageName: "on-semiconductor",
    link: "http://www.onsemi.com/",
    text: "On Semiconductor",
  },
];

export default function FeaturedClients() {
  return (
    <Styles>
      <SectionHeader>Featured Clients</SectionHeader>
      <GridItems gridItems={gridItems} columnCount={4} responsiveCount={1} />

      <p>
        And several more in other industries,{" "}
        <Link to="/contact">Contact Us</Link> to see if we have relevant
        experience for your project.
      </p>
    </Styles>
  );
}
