import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import GridItems from "./GridItems";

import SectionHeader from "./SectionHeader";

const Styles = styled.div`
  p {
    width: 66.6%;
    margin: auto;
    color: ${(props) => props.theme.text.paragraph};
  }
`;

const gridItems = [
  { imageName: "react", link: "", text: "React" },
  { imageName: "python", link: "", text: "" },
  { imageName: "amazon-web-services", link: "", text: "" },
  { imageName: "postgresql", link: "", text: "" },
  { imageName: "djangoImage", link: "", text: "Django - Python MVC/ORM" },
  { imageName: "mysql", link: "", text: "" },
  { imageName: "d3", link: "", text: "D3 Javascript Charts" },
  { imageName: "nodejs", link: "", text: "nodeJS" },
  { imageName: "Android-Logo", link: "", text: "Android Mobile Apps" },
  { imageName: "laravel-featured", link: "", text: "Laravel" },
];

export default function TechSpecialties() {
  return (
    <Styles>
      <SectionHeader>Tech Specialties</SectionHeader>
      <GridItems gridItems={gridItems} columnCount={5} responsiveCount={2} />
      <p>
        These are our most popular technologies, we have lots of other
        experience, too.
        <br />
        <Link to="/contact">Contact Us</Link>
      </p>
    </Styles>
  );
}
