import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RichText from "./RichText";

const PresentationStyles = styled.div`
  margin: 60px 0;
  h2 {
    text-align: left;
    font-size: 20px;
  }
  .presentation-container {
    display: flex;
  }

  hr {
    width: 50%;
    margin-left: 0px;
    color: #ccc;
    height: 1px;
    border: none;
    background-color: #ccc;
  }
  .presentation-content-container {
    width: 66.667%;
    margin: auto;
  }
  .presentation-title-author-wrapper {
    padding: 1em 17px;
    width: 20%;
  }
  @media screen and (max-width: 640px) {
    .presentation-container {
      display: block;
    }
    .presentation-content-container {
      width: 100%;
    }
    .presentation-title-author-wrapper {
      padding: 0;
      width: 100%;
    }
    hr {
      width: 100%;
    }
  }
`;

export default function Presentation({ title, authors, body }) {
  return (
    <PresentationStyles>
      <div className="presentation-container">
        <div className="presentation-title-author-wrapper">
          <h2>{title}</h2>
          <p>
            By{" "}
            {[authors.slice(0, -1).join(", "), authors.slice(-1)[0]].join(
              authors.length < 2 ? "" : " and "
            )}
          </p>
        </div>
        <div className="presentation-content-container">
          <RichText text={body} />
        </div>
      </div>
      <hr />
    </PresentationStyles>
  );
}

Presentation.propTypes = {
  title: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.string).isRequired,
  body: PropTypes.shape({}).isRequired,
};
