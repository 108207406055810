import * as React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const {
        data: {
          target: {
            gatsbyImageData,
            file: { contentType, url, fileName },
          },
        },
      } = node;

      switch (contentType) {
        case "image/png": {
          const image = getImage(gatsbyImageData);
          return <GatsbyImage image={image} />;
        }
        case "application/pdf":
          return <a href={url}>{fileName}</a>;
        default:
          return "nothing to see";
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      const {
        content,
        data: {
          target: {
            file: { contentType, url },
          },
        },
      } = node;

      switch (contentType) {
        case "application/pdf":
          return <a href={url}>{content.map((c) => c.value).join()}</a>;
        default:
          return "nothing to see";
      }
    },

    [INLINES.ENTRY_HYPERLINK]: (node) => {
      const {
        content,
        data: {
          target: { slug },
        },
      } = node;

      return <a href={`/blog/${slug}`}>{content.map((c) => c.value).join()}</a>;
    },
  },
};

export default function RichText({ text }) {
  return renderRichText(text, options);
}
