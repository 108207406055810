import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Image from "./Image";

const GridStyles = styled.div`
  padding: 17px;

  .grid {
    display: grid;
    grid-template-columns: ${(props) => props.gridColumnString};
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    padding: 1rem;
  }

  img {
    width: 100%;
    object-fit: scale-down;
  }

  @media screen and (max-width: 640px) {
    .grid {
      grid-template-columns: ${(props) => props.responsiveGridColumnString};
    }
  }
`;

export default function GridItems({ gridItems, columnCount, responsiveCount }) {
  const singleColumnString = "1fr ";
  const gridColumnString = singleColumnString.repeat(columnCount);
  const responsiveGridColumnString = singleColumnString.repeat(responsiveCount);

  return (
    <GridStyles
      gridColumnString={gridColumnString}
      responsiveGridColumnString={responsiveGridColumnString}
    >
      <div className="grid">
        {gridItems.map(({ text, link, imageName }) => (
          <div className="grid-item-container" key={text}>
            {link ? (
              <a href={link}>
                <Image
                  style={{ width: "100%", objectFit: "scale-down" }}
                  imageName={imageName}
                  alt={text}
                />
              </a>
            ) : (
              <Image
                style={{ width: "100%", objectFit: "scale-down" }}
                imageName={imageName}
                alt={text}
              />
            )}
          </div>
        ))}
      </div>
    </GridStyles>
  );
}

GridItems.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      imageName: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  columnCount: PropTypes.number.isRequired,
  responsiveCount: PropTypes.number.isRequired,
};
